import Vue from "vue";
import {is_empty} from "../utils";

const SNAP_RANGE = 3

export default {
    props: {
        component_data: Object,
        parent: Object,
        section: Boolean
    },
    data() {
      return {
        old_mouse_x: 0,
        old_mouse_y: 0,
        is_text: false,
        is_img: false,
        can_drag: false,
        tag: '',
        cloneDataComponent: {},
        oldV: "",
        mountedData: false,
        origin_parent: null
      };
    },
    watch: {
        component_data: {
            handler: function (val) {
                if (this.$store.state.edit_mode) {
                    if (['BaseMultiProductComponent', 'BaseMultiBlogComponent'].includes(this.$options.name)) {
                        if (!this.lodash.isEqual(this.lodash.omit(this.cloneDataComponent, 'child'), this.lodash.omit(val, 'child'))) {
                            this.cloneDataComponent = this.lodash.cloneDeep(this.lodash.omit(val, 'child'));
                        }
                    } else {
                        if (!this.lodash.isEqual(this.cloneDataComponent, val)) {
                            this.cloneDataComponent = this.lodash.cloneDeep(val);
                        }
                    }
                }
            },
            deep: true
        },
        cloneDataComponent: {
            handler: function (val, oldV) {
                let temp = [JSON.parse(JSON.stringify(val)), JSON.parse(JSON.stringify(oldV))]
                temp.forEach(t=>t.child && (t.child = t.child.filter(c=>c.node != 'element')))
                // console.log('cloneDataComponent', this, val, oldV, this.lodash.isEqual(temp[0],temp[1]), this.mountedData, this.$store.state.history_roll);
                if (this.$store.state.edit_mode && !this.lodash.isEqual(temp[0],temp[1])) {
                    let data = this.$store.state.layout; 
                    this.origin_parent =  JSON.stringify(data.child.filter((node) => node.tag === "html")[0])
                    let path = this.path_node_in_tree(JSON.parse(this.origin_parent), val)
                    if ((this.mountedData && !this.$store.state.history_roll && path) || this.$store.state.clone_component) {
                        if (this.lodash.isEmpty(this.oldV) || (oldV.attr && this._uid != oldV.attr['data-id'])) this.oldV = oldV;
                        this.push_stack_components(path, val, this);
                        if (this.$store.state.clone_component) {
                            this.$store.state.clone_component = false
                            this.mountedData = true
                        }
                    } else {
                        this.$store.state.history_roll = false
                        let count = this.component_data.child ? this.component_data.child.filter(c => c.attr && 'data-id' in c.attr).length : 0
                        // console.log('COUNT', count, this);
                        if (count <= this.$children.length) {
                            this.mountedData = true
                        }
                    }
                }
            },
            deep: true
        },
    },
    created() {
        // if (this.$store.state.edit_mode) {
        let template;
        let styling = {};
        let events = event_template(
            this.$options.propsData.component_data.events || {}
        );

        if (this.component_data.tag === 'form') {
            if (!events.submit) events.submit = {
                "open-dialog": ""
            }
        }

        if (
            this.$options.propsData.component_data.attr &&
            this.$options.propsData.component_data.attr.style
        ) {
            styling = this.$options.propsData.component_data.attr.style;
        }

        if (this.is_text) {
            template = text_styling_template(styling);
        } else if (this.is_img) {
            template = img_styling_template(styling);
        } else {
            template = container_styling_template(styling);
        }

        // if (
        //     this.$options.propsData.component_data.tag === 'a' &&
        //     this.$options.propsData.component_data.attr &&
        //     'href' in this.$options.propsData.component_data.attr &&
        //     this.$options.propsData.component_data.attr.href !== '#' &&
        //     !this.$options.propsData.component_data.attr.href.includes('javascript:void') &&
        //     is_empty(events.click['open-link'])
        // ) {
        //     events.click['open-link'] = this.$options.propsData.component_data.attr.href
        // }

        if (
            (!is_empty(events.click['open-link']) ||
                !is_empty(events.click['open-dialog']) ||
                !is_empty(events.click['apply-filter'])) && !template.cursor
        ) {
            template.cursor = 'pointer'
        }

        Vue.set(this.$options.propsData.component_data, "events", events);

        if (this.$options.propsData.component_data.attr) {
            Vue.set(this.$options.propsData.component_data.attr, "style", template);
            Vue.set(this.$options.propsData.component_data.attr, "data-id", this.data_id);
            if (this.$options.propsData.component_data.attr['t-esc']) {
                this.$options.propsData.component_data.attr['deepsel-esc'] = this.$options.propsData.component_data.attr['t-esc']
            } else {
                this.$options.propsData.component_data.attr['deepsel-esc'] ? '' : this.$options.propsData.component_data.attr['deepsel-esc'] = 0
            }

        } else {
            Vue.set(this.$options.propsData.component_data, "attr", {
                ...{style: template},
                'deepsel-esc': 0,
                "data-id": this.data_id
            });
        }

        // }

        // event scroll
        if (!this.$store.state.edit_mode && this.events.scroll["open-dialog"]) {
            console.log(this.component_data);
            window.addEventListener("scroll", this.handleDebounceScroll(this));
        }


    },
    mounted() {
        // if (this.component_data.attr && 'data-toggle' in this.component_data.attr) return
        let id = this.$el.getAttribute("data-id");
        if (id && this.component_data.attr && !this.is_nav_link) {
            this.$selector(`*[data-id='${id}']`).addEventListener('click', this.on_click)
        }
        // if (this.$store.state.edit_mode) this.push_snap_grid()
        let origin_header = this.in_header(this)
        let origin_footer = this.in_footer(this)
        origin_header && (this.$store.state.origin_header = origin_header)
        origin_footer && (this.$store.state.origin_footer = origin_footer)
    },
    updated() {
        let data = this.$store.state.layout;
        this.origin_parent = JSON.stringify(data.child.filter((node) => node.tag === "html")[0])
    },
    destroyed() {
        if (!this.$store.state.edit_mode && this.events && this.events.scroll && this.events.scroll["open-dialog"]) {
            window.addEventListener("scroll", this.handleDebounceScroll(this));
        }
    },
    computed: {
        is_dragging: {
            get() {
                return this.$store.state.is_dragging
            },
            set(val) {
                this.$store.state.is_dragging = val
            }
        },
        history_current_index: {
            get() {
                return this.$store.state.history_current_index
            },
            set(val) {
                this.$store.state.history_current_index = val
            }
        },
        history_length: {
            get() {
                return this.$store.state.history_length
            },
            set(val) {
                this.$store.state.history_length = val
            }
        },
        events() {
            return this.component_data.events;
        },
        filter() {
            return this.$store.state.filter;
        },
        common() {
            return this.$store.state.common_data;
        },
        desktop_transform() {
            return this.component_data.attr.style.transform_data.desktop;
        },
        tablet_transform() {
            return this.component_data.attr.style.transform_data.tablet;
        },
        mobile_transform() {
            return this.component_data.attr.style.transform_data.mobile;
        },
        transform() {
            if (
                this.desktop_transform ||
                this.tablet_transform ||
                this.mobile_transform
            ) {
                let res = "";
                if (this.mobile_transform) {
                    res += `@media only screen and (max-width: 600px) {${this.tag}[data-id="${this.data_id}"] {transform: ${this.mobile_transform} !important}`;
                }
                if (this.tablet_transform) {
                    res += ` @media only screen and (min-width: 600px) {${this.tag}[data-id="${this.data_id}"] {transform: ${this.tablet_transform} !important}`;
                }
                if (this.desktop_transform) {
                    res += ` @media only screen and (min-width: 992px) {${this.tag}[data-id="${this.data_id}"] {transform: ${this.desktop_transform} !important}`;
                }
                return res;
            } else return null;
        },
        data_id() {
            return this.id ||
                  // this.component_data.attr['data-id'] ||
                  this._uid
        },
        styling() {
            return this.$options.propsData.component_data.attr.style;
        },
        is_editing() {
            return this.$store.state.edit_mode && this.$store.state.editing === this;
        },
        is_next_editing() {
          return this.$store.state.edit_mode && this.$store.state.next_editing === this.data_id;
        },
        classing() {
          return { "deepsel-focus": this.is_editing || this.is_next_editing };
        },
        self() {
            return this;
        },
        parent_is_empty_shell() {
            let empty = false
            let parent = this.$parent
            if (parent && parent.is_text) {
                if (parent.$children.length > 1) {
                    let text_children = parent.$children.filter(c => c !== this && c.is_text)
                    empty = !is_empty(text_children) && text_children.every(c => c.$el.innerText === undefined)
                } else {
                    empty = true
                }
            }

            // console.log({empty})
            //
            // if (empty) {
            //     if (parseInt(window.getComputedStyle(parent.$el, null)
            //         .getPropertyValue('padding-top')
            //         .replace('px', '')) < 10) {
            //         if (parseInt(window.getComputedStyle(this.$el, null)
            //             .getPropertyValue('margin-top')
            //             .replace('px', '')) < 10) {
            //             return true
            //         }
            //     }
            // }
            // return false

            return empty
        },
        is_empty_shell() {
            return this.$children.length > 0 && this.$children[0].parent_is_empty_shell
        },
    },
    methods: {
        header_footer_changed(new_header, new_footer) {
            if (new_header){
                // console.log('ORIGIN_HEADER', this.$store.state.origin_header);
                // console.log('NEW_HEADER', new_header);
                return this.$store.state.origin_header != new_header
            }
            if (new_footer)
                return this.$store.state.origin_footer != new_footer
        },
        in_header(node) {
            let header_refs = ['header', 'Header', 'HEADER']
            if (node.component_data) {
                if (node.component_data.tag == 'header') return JSON.stringify(node.component_data) 
                if (node.component_data.attr) {
                    if ('deepsel-header' in node.component_data.attr || node.component_data.attr.id && header_refs.includes(node.component_data.attr.id)) return JSON.stringify(node.component_data)
                    if (node.component_data.attr.class) {
                        let class_arr = node.component_data.attr.class.split(' ')
                        if (class_arr.some(item => header_refs.includes(item))) return JSON.stringify(node.component_data)
                    }
                }
            }
            if (node.$parent) {
                let r = this.in_header(node.$parent)
                return r
            }
            return ''
        },
        in_footer(node) {
            let footer_refs = ['footer', 'Footer', 'FOOTER']
            if (node.component_data) {
                if (node.component_data.tag == 'footer') return JSON.stringify(node.component_data) 
                if (node.component_data.attr) {
                    if ('deepsel-footer' in node.component_data.attr || footer_refs.includes(node.component_data.attr.id)) return JSON.stringify(node.component_data)
                    if (node.component_data.attr.class) {
                        let class_arr = node.component_data.attr.class.split(' ')
                        if (class_arr.some(item => footer_refs.includes(item))) return JSON.stringify(node.component_data)
                    }
                }
            }
            if (node.$parent) {
                let r = this.in_footer(node.$parent)
                return r
            }
            return ''
        },
        path_node_in_tree(tree, node, path) {
            path = path || []
            if (tree && tree.attr && node && tree.attr["data-id"] && tree.attr["data-id"] === node.attr["data-id"]) {
              return path
            } else if (tree && !this.lodash.isEmpty(tree.child)) {
              let result = null;
              for (let index = 0; index < tree.child.length; index++) {
                result = this.path_node_in_tree(tree.child[index], node, path.concat(['child' , index]));
                if (result) return result;
              }
              return result;
            }
            return null;
        },
        get_coordinates(offset_x = null, offset_y = null) {
            let {top, left, height, width} = this.$el.getBoundingClientRect()
            if (offset_x) left -= parseInt(offset_x)
            if (offset_y) top -= parseInt(offset_y)
            return {
                left_x: left,
                right_x: left + width,
                mid_x: left + (width / 2),
                top_y: top,
                bottom_y: top + height,
                mid_y: top + (height / 2),
            }
        },
        // push_snap_grid() {
        //     let coordinates = this.get_coordinates()
        //     this.$store.state.snap_grid.x = this.$store.state.snap_grid.x.concat([
        //         coordinates.left_x,
        //         coordinates.mid_x,
        //         coordinates.right_x
        //     ].filter(val => val > 0).map(val => Math.round(val)))
        //     this.$store.state.snap_grid.y = this.$store.state.snap_grid.y.concat([
        //         coordinates.top_y,
        //         coordinates.mid_y,
        //         coordinates.bottom_y
        //     ].filter(val => val > 0).map(val => Math.round(val)))
        // },
        push_stack_components: Vue.lodash.debounce((path, val, self) => {
            let in_header = self.in_header(self)
            let in_footer = self.in_footer(self)
            if (in_header || in_footer) {
                if (self.header_footer_changed(in_header, in_footer)) {
                    parent.postMessage('header_footer_editing', '*')
                    self.$store.state.header_footer_changed = true
                } else self.$store.state.header_footer_changed = false
            }
            // console.log('PUSH_STACK', val, self);
            if ((!val.attr || !self.oldV.attr || val.attr['data-id'] == self.oldV.attr['data-id']) && !self.lodash.isEqual(val, self.oldV)) {
                // console.log('push_stack_components \n', val, self.oldV);
                self._push_stack_components({path, val, oldV: self.oldV})
                self.oldV = "";
            }
        }, 0),
        _push_stack_components(stack) {
            // console.log("_push_stack_components", this.$el);
            let stacks = []
            for(let i=0; i<sessionStorage.length; i++) {
                let key = sessionStorage.key(i);
                stacks[key] = JSON.parse(sessionStorage.getItem(key))
            }

            if (stacks.length > this.history_current_index) {
                stacks = stacks.slice(0, this.history_current_index)
            }
            stacks.push(stack)
            window.sessionStorage.clear();
            stacks.forEach((e, i) => {
                window.sessionStorage[i] = JSON.stringify(e)
            });

            this.history_current_index = stacks.length

        },
        on_click(event) {
          if (this.$store.state.edit_mode) {
            event.preventDefault()
            // event.stopImmediatePropagation();
          }
            // console.log(this.$parent.$options.name === 'BaseRenderComponent');
            // console.log(this.$parent.$el.offsetWidth > this.$el.offsetWidth && this.$parent.$el.offsetHeight > this.$el.offsetHeight)
            if (this.component_data && this.component_data.attr && this.component_data.attr.class && this.component_data.attr.class.includes('nav-link')) return 
            if (this.events && (this.events.click['apply-filter'] || this.events.click['open-dialog'] || this.events.click['open-link']) && !this.is_nav_link) {
                if (this.$parent.$el.offsetWidth > this.$el.offsetWidth && this.$parent.$el.offsetHeight > this.$el.offsetHeight) {
                    event.stopPropagation();
                }
                if (this.$options.name === 'BaseA') {
                    if (!this.events.click["open-link"] || this.$store.state.edit_mode) {
                        event.preventDefault()
                    }
                }

                if (!this.$store.state.edit_mode) {
                    if (this.events.click["open-dialog"] && this.events.click["open-dialog"] !== '0') {
                        window.$(`*[popup-id='${this.events.click["open-dialog"]}']`).modal('toggle')
                    }
                    if (this.events.click["open-link"] && this.events.click["open-link"] !== '0' && this.$options.name !== 'BaseA') {
                        let link = this.events.click["open-link"]
                        console.log(link);
                        console.log(event.target);
                        if (link[0] === '/') {
                            window.open(window.location.origin + link, this.events.target);
                        } else if (link.substring(0, 4) === 'tel:' || link.substring(0, 7) === 'mailto:') {
                            window.open(link, '_self');
                        } else {
                            window.open(
                                (link.match(/^https?:\/\//i)
                                    ? link
                                    : "https://" + link), this.events.target
                            );
                        }
                    }
                    if (this.events.click["apply-filter"] && this.events.click["apply-filter"] !== '0') {
                        let [filter_key, filter_value] = this.events.click["apply-filter"].split('=')

                        //store applied filter in url hash
                        let url_object = new URL(document.URL)
                        let hash = url_object.hash.substr(1)
                        let filter_object = {}
                        if (!is_empty(hash)) {
                            filter_object = hash
                                .split('&')
                                .reduce((result, item) => {
                                    let parts = item.split('=')
                                    result[parts[0]] = parts[1]
                                    return result
                                }, {});
                        }
                        filter_object[filter_key] = filter_value

                        url_object.hash = `#${new URLSearchParams(filter_object).toString()}`
                        window.location.href = url_object.href

                    }
                    if (this.$options.name === 'BaseHTMLContentComponent' && event.target.tagName === 'A') {
                        event.preventDefault()
                    }
                }

            }
        },
        handleDebounceScroll: Vue.lodash.debounce((that) => {
            let rec = that.$el.getBoundingClientRect();
            if (rec.top < (window.innerHeight || document.documentElement.clientHeight) && rec.bottom > 0) {
                let interval
                interval = setInterval(() => {
                    if (window.$ && window.$(`*[popup-id='${that.events.scroll["open-dialog"]}']`).modal) {
                        console.log("dsbsdbsdb ");
                        clearInterval(interval)
                        window.$(`*[popup-id='${that.events.scroll["open-dialog"]}']`).modal('toggle')
                        that.events.scroll["open-dialog"] = 0;
                    }
                }, 500);
            }
        }, 1000),
        clone() {
            if (this.$parent && this.$parent.component_data && this.$parent.component_data.child) {
                this.$store.state.loading = this.$vs.loading({type: 'points'})
                let that = this
                let parent_data_object = get_parent_data_object(this.$parent.component_data, this.component_data)
                let new_component = JSON.parse(JSON.stringify(this.component_data))
                delete new_component.attr['data-id']
                parent_data_object.child.push(new_component)
                this.$store.state.clone_component = true
                setTimeout(function () {
                    that.$store.state.loading.close()
                }, 300);
            }
        },
        remove() {
            let path = this.path_node_in_tree(JSON.parse(this.origin_parent), this.component_data)
            if (this.$parent && this.$parent.component_data && this.$parent.component_data.child) {
                this.oldV = this.component_data
                this.$store.state.loading = this.$vs.loading({type: 'points'})
                let that = this
                let parent_data_object = get_parent_data_object(this.$parent.component_data, this.component_data)
                let index = parent_data_object.child.indexOf(this.component_data)
                parent_data_object.child.splice(index, 1)
                setTimeout(function () {
                    that.$store.state.loading.close()
                }, 300);
            }
            this.$nextTick(()=>{
                this.push_stack_components(path, {}, this);
            })
        },
        //drag adapter
        mouse_down(e) {
          if (this.$store.state.edit_mode && !this.$store.state.editing_text) {
            if(e) e.stopPropagation()
            if (this.parent_is_empty_shell) {
              this.$parent.mouse_down()
              return
            }
            this.$store.state.editing = this;
            this.$store.state.editing_id = this.data_id;

            if (this.can_drag) {
                this.is_dragging = true;
                this.old_mouse_x = e.clientX;
                this.old_mouse_y = e.clientY;
                document.addEventListener("mousemove", this.mouse_move);
                document.addEventListener("mouseup", this.mouse_up);
            }
          }
        },
        //drag adapter
        mouse_move: Vue.lodash.debounce(function (e) {//do not use arrow function, 'this' will be undefined
            if (!this.is_dragging) return;

            var new_mouse_x = e.clientX;
            var new_mouse_y = e.clientY;

            let dy = new_mouse_y - this.old_mouse_y;
            let dx = new_mouse_x - this.old_mouse_x;

            let window_width = window.innerWidth;
            let target_style;

            if (window_width < 600) {
                target_style = this.component_data.attr.style.transform_data.mobile;
            } else if (window_width < 992) {
                target_style = this.component_data.attr.style.transform_data.tablet;
            } else {
                target_style = this.component_data.attr.style.transform_data.desktop;
            }


            // matrix( scaleX(), skewY(), skewX(), scaleY(), translateX(), translateY() )
            let transform_array = window.getComputedStyle(this.$el, null).getPropertyValue("transform") !== 'none'
                ? window.getComputedStyle(this.$el, null).getPropertyValue("transform").replace("matrix(", "").replace(")", "").split(",")
                : [1, 0, 0, 1, 0, 0]

            if (target_style) {
                if (target_style.includes('translate')) {
                    transform_array[4] = parseInt(target_style.replace("translate(", "").replace(")", "").split(",")[0]) + dx
                    transform_array[5] = parseInt(target_style.replace("translate(", "").replace(")", "").split(",")[1]) + dy
                } else {
                    transform_array = target_style.replace("matrix(", "").replace(")", "").split(",");
                    transform_array[4] = parseInt(transform_array[4]) + dx
                    transform_array[5] = parseInt(transform_array[5]) + dy
                }
            } else {
                transform_array[4] = dx
                transform_array[5] = dy
            }

            let coordinates
            let transform = window.getComputedStyle(this.$el, null).getPropertyValue("transform")
            if (transform !== 'none') {
                let t_array = transform.replace("matrix(", "").replace(")", "").split(",")
                coordinates = this.get_coordinates(t_array[4], t_array[5])
            } else {
                coordinates = this.get_coordinates()
            }

            coordinates.left_x += transform_array[4]
            coordinates.mid_x += transform_array[4]
            coordinates.right_x += transform_array[4]
            coordinates.top_y += transform_array[5]
            coordinates.mid_y += transform_array[5]
            coordinates.bottom_y += transform_array[5]

            let snap_x = null
            let document_mid_x = Math.round(document.body.clientWidth / 2)

            for (let key in coordinates) {
                let val = Math.round(coordinates[key])
                if (key.includes('x') && !snap_x) {
                    let diff_x = document_mid_x - val
                    if (Math.abs(diff_x) <= SNAP_RANGE) {
                        snap_x = diff_x
                    }
                }
            }

            if (snap_x !== null) {
                transform_array[4] += snap_x
                this.$store.state.snap_x = document_mid_x
            } else {
                this.$store.state.snap_x = null
            }

            let new_style = `matrix(${transform_array.join(',')})`

            if (window_width < 600) this.component_data.attr.style.transform_data.mobile = new_style
            else if (window_width < 992) this.component_data.attr.style.transform_data.tablet = new_style
            else this.component_data.attr.style.transform_data.desktop = new_style

            this.old_mouse_x = e.clientX;
            this.old_mouse_y = e.clientY;
            this.mouse_enter();
        }, 5, {
            leading: true,
            trailing: false
        }),
        //drag adapter
        mouse_up() {
            if (this.is_dragging) {
                this.is_dragging = false;
                this.component_data.attr.style.cursor = null;
                document.removeEventListener("mousemove", this.mouse_move);
                document.removeEventListener("mouseup", this.mouse_up);
            }
        },
        async mouse_enter() {
            // console.log('mouse_enter BEFORE', this.tag, this.$options.name, this._uid, this.component_data)
            // if (!this.$store.state.edit_mode) return
            // if (this.$store.state.editing_text) return
            // if (this.parent_is_empty_shell) {
            //     this.$parent.mouse_enter()
            //     return
            // }
            // this.$store.state.editing = this;
            if (this.$store.state.edit_mode && !this.$store.state.is_dragging) {
              // if (this.parent_is_empty_shell) {
              //   this.$parent.mouse_down()
              //   return
              // }
              this.$store.state.next_editing = this.data_id;
              return
            }
            // console.log('mouse_enter AFTER', this.tag, this.$options.name, this._uid, this.component_data)
        },
        on_italic() {
            if (!this.component_data.attr.style.fontStyle) {
                this.component_data.attr.style.fontStyle = "italic";
            } else this.component_data.attr.style.fontStyle = null;
        },
        on_bold() {
            if (!this.component_data.attr.style.fontWeight) {
                this.component_data.attr.style.fontWeight = "bold";
            } else this.component_data.attr.style.fontWeight = null;
        },
        on_strike() {
            if (!this.component_data.attr.style.textDecoration) {
                this.component_data.attr.style.textDecoration = "line-through";
            } else this.component_data.attr.style.textDecoration = null;
        },
        change_font(font) {
            this.component_data.attr.style.fontFamily = font;
        },
        change_esc(esc) {
            this.component_data.attr = {
                ...this.component_data.attr,
                ...{"deepsel-esc": esc}
            }
        },
        change_collection(v) {
            this.component_data.attr['deepsel-multi-product-collection'] = v
        },
        change_font_size(size) {
            this.component_data.attr.style.fontSize = `${size}pt`;
        },
        change_onclick(v) {
            this.component_data.attr.onclick = v
        },
    },
}

function text_styling_template(options = {}) {
  let res = JSON.parse(
    JSON.stringify({
      backgroundImage: null,
      fontWeight: null,
      fontStyle: null,
      textDecoration: null,
      textAlign: null,
      color: null,
      backgroundColor: null,
      fontFamily: null,
      fontSize: null,
      cursor: null,
      opacity: null,
      boxShadow: null,
      borderColor: null,
      borderStyle: null,
      borderWidth: null,
      borderRadius: null,
      transform_data: {
        desktop: null,
        tablet: null,
        mobile: null,
      },
      // flex_data: {
      //   desktop: null,
      //   tablet: null,
      //   mobile: null,
      // },
      // padding_data: {
      //   desktop: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   tablet: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   mobile: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      // },
      // margin_data: {
      //   desktop: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   tablet: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   mobile: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      // },
      // size: {
      //   desktop: null,
      //   tablet: null,
      //   mobile: null,
      // },
    })
  );
  for (let key in options) {
    
    res[key] = options[key];
  }
  return res;
}

function img_styling_template(options = {}) {
  let res = JSON.parse(
    JSON.stringify({
      cursor: null,
      borderColor: null,
      borderWidth: null,
      borderStyle: null,
      boxShadow: null,
      backgroundColor: null,
      borderRadius: null,
      opacity: null,
      transform_data: {
        desktop: null,
        tablet: null,
        mobile: null,
      },
      // size: {
      //   desktop: null,
      //   tablet: null,
      //   mobile: null,
      // },
      // margin_data: {
      //   desktop: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   tablet: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   mobile: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      // },
      // padding_data: {
      //   desktop: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   tablet: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   mobile: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      // },
      // flex_data: {
      //   desktop: null,
      //   tablet: null,
      //   mobile: null,
      // },
    })
  );
  for (let key in options) {
    if ((key === "flex_data" || key === "size" || key === "margin_data" || key === "padding_data")) {
      console.log(options[key]);
    }
    res[key] = options[key];
  }
  return res;
}

function container_styling_template(options = {}) {
  let res = JSON.parse(
    JSON.stringify({
      backgroundImage: null,
      cursor: null,
      borderColor: null,
      borderWidth: null,
      borderStyle: null,
      opacity: null,
      borderRadius: null,
      boxShadow: null,
      backgroundColor: null,
      transform_data: {
        desktop: null,
        tablet: null,
        mobile: null,
      },
      // size: {
      //   desktop: null,
      //   tablet: null,
      //   mobile: null,
      // },
      // flex_data: {
      //   desktop: null,
      //   tablet: null,
      //   mobile: null,
      // },
      // padding_data: {
      //   desktop: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   tablet: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   mobile: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      // },
      // margin_data: {
      //   desktop: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   tablet: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      //   mobile: {
      //     top: null,
      //     right: null,
      //     bottom: null,
      //     left: null
      //   },
      // },
    })
  );

  for (let key in options) {
    res[key] = options[key]
  }
  return res;
}

function event_template(options = {}) {
    let res = JSON.parse(
        JSON.stringify({
            click: {
              "open-link": "",
              "apply-filter": "",
              "open-dialog": "",
            },
            scroll: {
              "open-dialog": "",
            },
            target: "_self"
        })
    );

    for (let key in options) res[key] = options[key]
    return res
}

function get_parent_data_object(ancestor, target) {
    if (ancestor.child && ancestor.child.length > 0) {
        for (let c of ancestor.child) {
            if (c === target) {
                return ancestor
            }
            if (c.child) {
                let res = get_parent_data_object(c, target)
                if (res) return res
            }
        }
    } else return null
}

// function get_object_from_string_path(o, s) {
//     s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
//     s = s.replace(/^\./, '');           // strip a leading dot
//     var a = s.split('.');
//     for (var i = 0, n = a.length; i < n; ++i) {
//         var k = a[i];
//         if (k in o) {
//             o = o[k];
//         } else {
//             return;
//         }
//     }
//     return o;
// }
